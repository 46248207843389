export default {
  "admissions.id": "admission 資料庫 ID",
  "admissions.school_id": "學校 ID",
  "admissions.entrance_difficulty": "入學難度",
  "admissions.hs_diploma_policy": "高中畢業",
  "admissions.hs_program_policy": "高中課程",
  "admissions.hs_english_required": "高中英文課程要求",
  "admissions.hs_math_required": "高中數學課程要求",
  "admissions.hs_science_required": "高中自然課程要求",
  "admissions.hs_language_required": "高中語言課程要求",
  "admissions.hs_social_required": "高中社會課程要求",
  "admissions.hs_history_required": "高中歷史課程要求",
  "admissions.hs_elective_required": "高中選修課程要求",
  "admissions.hs_english_recommended": "高中英文課程建議",
  "admissions.hs_math_recommended": "高中數學課程建議",
  "admissions.hs_science_recommended": "高中自然課程建議",
  "admissions.hs_language_recommended": "高中語言課程建議",
  "admissions.hs_social_recommended": "高中社會課程建議",
  "admissions.hs_history_recommended": "高中歷史課程建議",
  "admissions.hs_elective_recommended": "高中選修課程建議",
  "admissions.satact_policy": "SAT/ACT政策",
  "admissions.sat_policy": "SAT政策",
  "admissions.act_policy": "ACT政策",
  "admissions.satact_scores_due": "SAT/ACT分數遞交截止日期",
  "admissions.sat_scores_due": "SAT分數遞交截止日期",
  "admissions.act_scores_due": "ACT分數遞交截止日期",
  "admissions.satsat2act_policy": "SAT/ACT/SAT2分數遞交截止日期",
  "admissions.sat2_policy": "SAT2分數遞交截止日期",
  "admissions.sat_essay_policy": "SAT作文政策",
  "admissions.act_essay_policy": "ACT作文政策",
  "admissions.essay_policy": "作文政策",
  "admissions.ao_address": "入學辦公室地址",
  "admissions.ao_city": "城市",
  "admissions.ao_state": "地區",
  "admissions.ao_zipcode1": "郵遞區號1",
  "admissions.ao_zipcode2": "郵遞區號2",
  "admissions.ao_phone": "電話",
  "admissions.ao_email": "電子信箱",
  "admissions.rd_deadline_is_rolling": "正常申請是否為滾動申請",
  "admissions.rd_deadline": "申請截止日期",
  "admissions.domestic_application_fee": "申請費用",
  "admissions.state_application_fee": "州內學生申請費用",
  "admissions.intl_application_fee": "國際學生申請費用",
  "admissions.tr_application_fee": "轉學生申請費用",
  "admissions.has_application_fee_waiver": "可否免除申請費用",
  "admissions.rd_notification_date_is_rolling": "正常申請通知是否為滾動通知",
  "admissions.rd_notification_date": "正常申請通知日期",
  "admissions.accept_offer_admission_date": "錄取知回覆截止日",
  "admissions.has_waitlist": "是否有候補名單",
  "admissions.can_defer": "是否可延後錄取",
  "admissions.can_transfer": "轉學申請",
  "admissions.has_ed1": "是否有ED1",
  "admissions.has_ed2": "是否有ED2",
  "admissions.ed1_deadline_is_rolling": "ED1是否為滾動申請",
  "admissions.ed1_deadline": "ED I 申請截止日期",
  "admissions.ed2_deadline_is_rolling": "ED2是否為滾動申請",
  "admissions.ed2_deadline": "ED II 申請截止日期",
  "admissions.ed1_notification_date_is_rolling": "ED1錄取通知是否為滾動通知",
  "admissions.ed1_notification_date": "ED1通知日期",
  "admissions.ed2_notification_date_is_rolling": "ED2錄取通知是否為滾動通知",
  "admissions.ed2_notification_date": "ED2通知日期",
  "admissions.has_ea1": "是否有EA1",
  "admissions.has_ea2": "是否有EA2",
  "admissions.ea1_deadline_is_rolling": "EA1是否為滾動申請",
  "admissions.ea1_deadline": "EA1 申請截止日期",
  "admissions.ea2_deadline_is_rolling": "EA2是否為滾動申請",
  "admissions.ea2_deadline": "EA2 申請截止日期",
  "admissions.ea1_notification_date_is_rolling": "EA1錄取通知是否為滾動通知",
  "admissions.ea1_notification_date": "EA1通知日期",
  "admissions.ea2_notification_date_is_rolling": "EA2錄取通知是否為滾動通知",
  "admissions.ea2_notification_date": "EA2通知日期",
  "admissions.has_tr": "是否有轉學申請",
  "admissions.tr_deadline_is_rolling": "轉學申請是否為滾動申請",
  "admissions.tr_deadline": "Transfer 申請截止日期",
  "admissions.tr_notification_date_is_rolling": "轉學錄取通知是否為滾動通知",
  "admissions.tr_notification_date": "轉學申請通知日期",
  "admissions.has_rea": "是否有REA",
  "admissions.accepts_commonapp": "Common Application",
  "admissions.commonapp_id": "Common App ID",
  "admissions.coalition_id": "Coalition App ID",
  "admissions.has_commonapp_supplement": "Common App 是否需要額外申請表",
  "admissions.accepts_universalapp": "Universal College Application",
  "admissions.has_universalapp_supplement": "Universal College Application 是否需要額外申請表",
  "admissions.accepts_electronicapp": "線上申請表",
  "admissions.has_electronic_supplement": "是否有線上額外申請表",
  "admissions.interview_policy": "面試政策",
  "admissions.college_essay_policy": "大學作文政策",
  "admissions.recommendation_policy": "推薦信政策",
  "admissions.app_other_policy": "申請表其他政策",
  "admissions.financial_need_considered": "金錢需求",
  "admissions.impt_hs_record": "高中報告",
  "admissions.impt_gpa": "學業 GPA",
  "admissions.impt_tests": "標準化考試",
  "admissions.impt_rank": "在校排名",
  "admissions.impt_recommendations": "推薦信",
  "admissions.impt_essay": "作文",
  "admissions.impt_interview": "面試",
  "admissions.impt_interest": "學生對學生感興趣程度",
  "admissions.impt_activities": "活動",
  "admissions.impt_volunteering": "義工",
  "admissions.impt_talen": "才藝",
  "admissions.impt_personality": "個性",
  "admissions.impt_first_gen": "是否為第一代",
  "admissions.impt_state_residency": "是否為州居民",
  "admissions.impt_geography": "地理位置",
  "admissions.impt_alumnus_relationship": "與畢業生關係",
  "admissions.impt_religion": "宗教",
  "admissions.impt_ethnicity": "種族",
  "admissions.impt_work_experience": "工作經驗",
  "admissions.applicant_overall_count": "整體申請人數",
  "admissions.applicant_w_count": "女性整體申請人數",
  "admissions.applicant_m_count": "男性整體申請人數",
  "admissions.acceptance_pct_overall": "整體錄取率",
  "admissions.acceptance_pct_w": "女性整體率取率",
  "admissions.acceptance_pct_m": "男性整體率取率",
  "admissions.admitted_overall_count": "率取人數",
  "admissions.admitted_w_count": "女性錄取人數",
  "admissions.admitted_m_count": "男性率取人數",
  "admissions.enrolled_overall_count": "就讀人數",
  "admissions.enrolled_w_count": "女性就讀人數",
  "admissions.enrolled_m_count": "男性就讀人數",
  "admissions.yield_pct_overall": "就讀率",
  "admissions.yield_pct_w": "女性就讀率",
  "admissions.yield_pct_m": "男性就讀率",
  "admissions.ed_acceptance_pct_overall": "ED 總錄取率",
  "admissions.ea_acceptance_pct_overall": "EA 總錄取率",
  "admissions.ed_applicant_overall_count": "ED 總申請人數",
  "admissions.ea_applicant_overall_count": "EA 總申請人數",
  "admissions.ed_admitted_overall_count": "ED 總錄取人數",
  "admissions.ea_admitted_overall_count": "EA 總錄取人數",
  "admissions.waitlist_offered_count": "候補名單人數",
  "admissions.waitlist_accepted_count": "候補名單錄取人數",
  "admissions.waitlist_admitted_count": "候補名單就讀人數",
  "admissions.avg_gpa": "平均GPA",
  "admissions.gpa375_pct": "大一新生 GPA 3.75 以上",
  "admissions.gpa350_pct": "大一新生 GPA 3.50 - 3.74",
  "admissions.gpa325_pct": "大一新生 GPA 3.25 - 3.49",
  "admissions.gpa300_pct": "大一新生 GPA 3.00 - 3.24",
  "admissions.gpa250_pct": "大一新生 GPA 2.50 - 2.99",
  "admissions.gpa200_pct": "大一新生 GPA 2.00 - 2.49",
  "admissions.avg_sat_math": "大一新生 SAT 數學平均分",
  "admissions.avg_sat_math_min": "大一新生 SAT 數學 PR25 值分數",
  "admissions.avg_sat_math_max": "大一新生 SAT 數學 PR75 值分數",
  "admissions.sat_math700_pct": "大一新生 SAT 700 - 800",
  "admissions.sat_math600_pct": "大一新生 SAT 600 - 700",
  "admissions.sat_math500_pct": "大一新生 SAT 500 - 600",
  "admissions.sat_math400_pct": "大一新生 SAT 400 - 500",
  "admissions.sat_math300_pct": "大一新生 SAT 300 - 400",
  "admissions.sat_math200_pct": "大一新生 SAT 200 - 300",
  "admissions.avg_sat_e": "大一新生 SAT 英文平均分",
  "admissions.avg_sat_english_min": "大一新生 SAT 英文 PR25 值分數",
  "admissions.avg_sat_english_max": "大一新生 SAT 英文 PR75 值分數",
  "admissions.sat_english700_pct": "大一新生 SAT EBRW 700 - 800",
  "admissions.sat_english600_pct": "大一新生 SAT EBRW 600 - 700",
  "admissions.sat_english500_pct": "大一新生 SAT EBRW 500 - 600",
  "admissions.sat_english400_pct": "大一新生 SAT EBRW 400 - 500",
  "admissions.sat_english300_pct": "大一新生 SAT EBRW 300 - 400",
  "admissions.sat_english200_pct": "大一新生 SAT EBRW 200 - 300",
  "admissions.avg_act": "大一新生 ACT 平均分",
  "admissions.avg_act_min": "大一新生 ACT PR25 值分數",
  "admissions.avg_act_max": "大一新生 ACT PR75 值分數",
  "admissions.avg_act30_pct": "大一新生 ACT 30 - 36",
  "admissions.avg_act24_pct": "大一新生 ACT 24 - 29",
  "admissions.avg_act18_pct": "大一新生 ACT 18 - 23",
  "admissions.avg_act12_pct": "大一新生 ACT 12 - 17",
  "admissions.avg_act6_pct": "大一新生 ACT 6 - 11",
  "admissions.avg_act0_pct": "大一新生 ACT 5 or Below",
  "admissions.ielts_1st_yr_req": "大一雅思要求",
  "admissions.toefl_1st_yr_req": "大一托福要求",
  "admissions.duolingo_1st_yr_req": "大一Duolingo要求",
  "admissions.ielts_1st_yr_intl_req": "大一國際生雅思要求",
  "admissions.toefl_1st_yr_intl_req": "大一國際生托福要求",
  "admissions.duolingo_1st_yr_intl_req": "大一國際生Duolingo要求",
  "admissions.has_special_intl_policy": "特殊國際生政策",
  "admissions.hs_rank_10_pct": "高中校內排名前 10%",
  "admissions.hs_rank_25_pct": "高中校內排名前 25%",
  "admissions.hs_rank_50_pct": "高中校內排名前 50%",
  "admissions.national_merit_pct": "優秀生獎學金 (National Merit Scholar)",
  "admissions.valedictorian_pct": "高中狀元 (Valedictorian)",
  "admissions.class_president_pct": "高中學年代表 (Class President)",
  "admissions.student_gov_pct": "學生自治會 (Student Government) 幹部",
  "admissions.toefl_requirement": "TOEFL 要求",
  "admissions.toefl_requirement_comments": "TOEFL 要求備註",
  "admissions.ielts_requirement": "IELTS 要求",
  "admissions.ielts_requirement_comments": "IELTS 要求備註",
  "admissions.duolingo_requirement": "Duolingo 要求",
  "admissions.duolingo_requirement_comments": "Duolingo 要求備註",
  "admissions.admissions_info_url": "大學招生資訊",
  "admissions.admissions_portal_url": "查看申請狀態",
  "admissions.admissions_transfer_url": "轉學生申請資訊",
  "admissions.undergrad_1st_yr_admissions_req_url": "大一申請需求網站",
  "admissions.undergrad_1st_yr_int_admissions_req_url": "大一國際生申請要求網站",
  "admissions.created_at": "資料新增日期",
  "admissions.updated_at": "資料更新日期",
  "academics.id": "Academics 資料庫 ID",
  "academics.school_id": "學校 ID",
  "academics.calendar_system": "學業課表種類",
  "academics.catalog_type": "課程表",
  "academics.popular_majors": "最受歡迎的主修",
  "academics.la_pd_programs": "通識/專科雙修學程",
  "academics.special_programs": "特殊學程",
  "academics.has_summer_session": "提供暑期課程",
  "academics.has_study_abroad": "提供國外交換學生",
  "academics.has_online_degree": "線上學位",
  "academics.has_gened": "課程及畢業要求是否需要通識課程",
  "academics.has_comp_req": "課程及畢業要求學生是否需要電腦",
  "academics.has_lang_req": "課程及畢業要求是否需要第二外語",
  "academics.has_stem_req": "課程及畢業要求是否需要數理",
  "academics.fulltime_faculty_count": "全職教職員",
  "academics.parttime_faculty_count": "兼職教職員",
  "academics.phd_faculty_pct": "有 Ph.D. 或最高學位的全職教職員",
  "academics.class_size_9_pct": "班級人數 2-9",
  "academics.class_size_19_pct": "班級人數 10-19",
  "academics.class_size_29_pct": "班級人數 20-29",
  "academics.class_size_39_pct": "班級人數 30-39",
  "academics.class_size_49_pct": "班級人數 40-49",
  "academics.class_size_99_pct": "班級人數 50-99",
  "academics.class_size_100_pct": "班級人數 100 以上",
  "academics.section_size_9_pct": "小班人數 2-9",
  "academics.section_size_19_pct": "小班人數 10-19",
  "academics.section_size_29_pct": "小班人數 20-29",
  "academics.section_size_39_pct": "小班人數 30-39",
  "academics.section_size_49_pct": "小班人數 40-49",
  "academics.section_size_99_pct": "小班人數 50-99",
  "academics.section_size_100_pct": "小班人數 Over 100",
  "academics.accepts_ib": "接受 IB 課程提早畢業",
  "academics.accepts_ap": "接受 AP 課程提早畢業",
  "academics.has_sophomore_standing": "接受跳級入學 (Sophomore Standing)",
  "academics.has_campus_library": "是否有圖書館",
  "academics.library_holdings_count": "館藏",
  "academics.campus_computers_count": "校園電腦數量",
  "academics.has_internet_access": "是否提供網路/Email",
  "academics.has_remedial_instruction": "輔導補救教學 (Remedial Instruction)",
  "academics.has_tutoring": "是否有家教",
  "academics.has_disability_service": "是否有給學習障礙學生的服務",
  "academics.disability_services": "是否有給身障生的服務",
  "academics.created_at": "academics 資料新增日期",
  "academics.updated_at": "academics 資料更新日期",
  "campus.id": "campus 資料庫 ID",
  "campus.school_id": "學校 ID",
  "campus.city_population": "城市人口大小",
  "campus.nearest_metro": "最近都市",
  "campus.campus_size_acre": "校園大小",
  "campus.temp_low": "一月平均低溫",
  "campus.temp_high": "九月平均高溫",
  "campus.rain_days": "一年下雨天數",
  "campus.map_url": "校園地圖",
  "campus.nearest_bus_dist_mi": "最近巴士站",
  "campus.nearest_train_dist_mi": "最近火車站",
  "campus.has_housing": "大學宿舍",
  "campus.housing_type": "宿舍種類",
  "campus.housing_requirement": "住宿要求",
  "campus.housing_guarantee": "大一新生住宿保證",
  "campus.housing_pct": "學生住宿百分比",
  "campus.offcampus_pct": "非住宿生百分比",
  "campus.offcampus_assistance": "提供學生校外住宿輔助",
  "campus.has_alarm_devices": "24小時緊急電話及警報裝置",
  "campus.has_security": "24小時保全巡邏",
  "campus.has_escort": "深夜接駁服務",
  "campus.has_e_entrance": "宿舍門禁管控",
  "campus.other_security_resources": "其他校園安全資源",
  "campus.has_health_serv": "健康醫療服務",
  "campus.has_counseling": "諮商服務",
  "campus.has_childcare": "托兒服務",
  "campus.athletic_conference": "體育聯盟",
  "campus.mascot": "校隊吉祥物",
  "campus.school_colors": "學校顏色",
  "campus.has_w_baseball": "是否有女子棒球隊",
  "campus.has_w_basketball": "是否有女子藍球隊",
  "campus.has_w_crew": "是否有女子划船隊",
  "campus.has_w_cc": "是否有女子cc隊",
  "campus.has_w_fencing": "是否有女子擊劍隊",
  "campus.has_w_hockey": "是否有女子曲棍球隊",
  "campus.has_w_football": "是否有女子美式足球隊",
  "campus.has_w_golf": "是否有女子高爾夫隊",
  "campus.has_w_icehockey": "是否有女子冰上曲棍球隊",
  "campus.has_w_lax": "是否有女子袋棍球隊",
  "campus.has_w_rugby": "是否有女子橄欖球隊 (rugby)",
  "campus.has_w_sailing": "是否有女子划船隊",
  "campus.has_w_cc_ski": "是否有女子滑雪隊",
  "campus.has_w_baseball_scho": "是否有女子棒球獎學金",
  "campus.has_w_basketball_scho": "是否有女子籃球獎學金",
  "campus.has_w_crew_scho": "是否有女子划船獎學金",
  "campus.has_w_cc_scho": "是否有女子棒cc獎學金",
  "campus.has_w_fencing_scho": "是否有女子擊劍獎學金",
  "campus.has_w_hockey_scho": "是否有女子曲棍球獎學金",
  "campus.has_w_football_scho": "是否有女子美式足球獎學金",
  "campus.has_w_golf_scho": "是否有女子高爾夫獎學金",
  "campus.has_w_icehockey_scho": "是否有女子冰上曲棍球獎學金",
  "campus.has_w_lax_scho": "是否有女子袋棍球獎學金",
  "campus.has_w_rugby_scho": "是否有女子橄欖球 (rugby) 獎學金",
  "campus.has_w_sailing_scho": "是否有女子划船獎學金",
  "campus.has_w_cc_ski_scho": "是否有女子滑雪獎學金",
  "campus.has_m_baseball": "是否有男子棒球隊",
  "campus.has_m_basketball": "是否有男子籃球隊",
  "campus.has_m_crew": "是否有男子划船隊",
  "campus.has_m_cc": "是否有男子cc隊",
  "campus.has_m_fencing": "是否有男子擊劍隊",
  "campus.has_m_hockey": "是否有男子曲棍球隊",
  "campus.has_m_football": "是否有男子美式足球隊",
  "campus.has_m_golf": "是否有男子高爾夫隊",
  "campus.has_m_icehockey": "是否有男子冰上曲棍球隊",
  "campus.has_m_lax": "是否有男子袋棍球隊",
  "campus.has_m_rugby": "是否有男子橄欖球隊 (rugby)",
  "campus.has_m_sailing": "是否有男子划船隊",
  "campus.has_m_cc_ski": "是否有男子滑雪隊",
  "campus.has_m_baseball_scho": "是否有男子棒球獎學金",
  "campus.has_m_basketball_scho": "是否有男子籃球獎學金",
  "campus.has_m_crew_scho": "是否有男子划船獎學金",
  "campus.has_m_cc_scho": "是否有男子cc獎學金",
  "campus.has_m_fencing_scho": "是否有男子擊劍獎學金",
  "campus.has_m_hockey_scho": "是否有男子曲棍球獎學金",
  "campus.has_m_football_scho": "是否有男子美式足球獎學金",
  "campus.has_m_golf_scho": "是否有男子高爾夫獎學金",
  "campus.has_m_icehockey_scho": "是否有男子冰上曲棍球獎學金",
  "campus.has_m_lax_scho": "是否有男子袋棍球獎學金",
  "campus.has_m_rugby_scho": "是否有男子橄欖球 (rugby) 獎學金",
  "campus.has_m_sailing_scho": "是否有男子划船獎學金",
  "campus.has_m_cc_ski_scho": "是否有男子滑雪獎學金",
  "campus.has_w_dh_ski": "女子滑雪隊",
  "campus.has_w_soccer": "女子足球隊",
  "campus.has_w_softball": "女子壘球隊",
  "campus.has_w_squash": "女子壁球隊",
  "campus.has_w_swim": "女子游泳隊",
  "campus.has_w_tennis": "女子網球隊",
  "campus.has_w_track": "女子田徑隊",
  "campus.has_w_vball": "女子排球隊",
  "campus.has_w_wpolo": "女子水球隊",
  "campus.has_w_wrestling": "女子摔角隊",
  "campus.has_w_dh_ski_scho": "是否有女子滑雪獎學金",
  "campus.has_w_soccer_scho": "是否有女子足球獎學金",
  "campus.has_w_softball_scho": "是否有女子壘球獎學金",
  "campus.has_w_squash_scho": "是否有女子壁球獎學金",
  "campus.has_w_swim_scho": "是否有女子游泳獎學金",
  "campus.has_w_tennis_scho": "是否有女子網球獎學金",
  "campus.has_w_track_scho": "是否有女子田徑獎學金",
  "campus.has_w_vball_scho": "是否有女子排球獎學金",
  "campus.has_w_wpolo_scho": "是否有女子水球獎學金",
  "campus.has_w_wrestling_scho": "是否有女子摔角獎學金",
  "campus.has_m_dh_ski": "男子滑雪隊",
  "campus.has_m_soccer": "男子足球隊",
  "campus.has_m_softball": "男子壘球隊",
  "campus.has_m_squash": "男子壁球隊",
  "campus.has_m_swim": "男子游泳隊",
  "campus.has_m_tennis": "男子網球隊",
  "campus.has_m_track": "男子田徑隊",
  "campus.has_m_vball": "男子排球隊",
  "campus.has_m_wpolo": "男子水球隊",
  "campus.has_m_wrestling": "男子摔角隊",
  "campus.has_m_dh_ski_scho": "是否有男子滑雪獎學金",
  "campus.has_m_soccer_scho": "是否有男子足球獎學金",
  "campus.has_m_softball_scho": "是否有男子壘球獎學金",
  "campus.has_m_squash_scho": "是否有男子壁球獎學金",
  "campus.has_m_swim_scho": "是否有男子游泳獎學金",
  "campus.has_m_tennis_scho": "是否有男子網球獎學金",
  "campus.has_m_track_scho": "是否有男子田徑獎學金",
  "campus.has_m_vball_scho": "是否有男子排球獎學金",
  "campus.has_m_wpolo_scho": "是否有男子水球獎學金",
  "campus.has_m_wrestling_scho": "是否有男子摔角獎學金",
  "campus.has_w_baseball_club": "女子棒球社",
  "campus.has_w_basketball_club": "女子籃球社",
  "campus.has_w_crew_club": "女子划船社",
  "campus.has_w_cc_club": "女子cc社",
  "campus.has_w_fencing_club": "女子擊劍社",
  "campus.has_w_hockey_club": "女子曲棍球社",
  "campus.has_w_football_club": "女子美式足球社",
  "campus.has_w_golf_club": "女子高爾夫球社",
  "campus.has_w_icehockey_club": "女子冰上曲棍球社",
  "campus.has_w_lax_club": "女子袋棍球社",
  "campus.has_w_rugby_club": "女子橄欖球社 (rugby) ",
  "campus.has_w_sailing_club": "女子划船社",
  "campus.has_w_cc_ski_club": "女子cc滑雪社",
  "campus.has_w_dh_ski_club": "女子dh滑雪社",
  "campus.has_w_soccer_club": "女子足球社",
  "campus.has_w_softball_club": "女子壘球社",
  "campus.has_w_squash_club": "女子壁球社",
  "campus.has_w_swim_club": "女子游泳社",
  "campus.has_w_tennis_club": "女子網球社",
  "campus.has_w_track_club": "女子田徑社",
  "campus.has_w_vball_club": "女子排球社",
  "campus.has_w_wpolo_club": "女子水球社",
  "campus.has_w_wrestling_club": "女子摔角社",
  "campus.has_m_baseball_club": "男子棒球社",
  "campus.has_m_basketball_club": "男子籃球社",
  "campus.has_m_crew_club": "男子划船社",
  "campus.has_m_cc_club": "男子cc社",
  "campus.has_m_fencing_club": "男子擊劍社",
  "campus.has_m_hockey_club": "男子曲棍球社",
  "campus.has_m_football_club": "男子足球社",
  "campus.has_m_golf_club": "男子高爾夫球社",
  "campus.has_m_icehockey_club": "男子冰上曲棍球社",
  "campus.has_m_lax_club": "男子袋棍球社",
  "campus.has_m_rugby_club": "男子曲棍球社 (rugby)",
  "campus.has_m_sailing_club": "男子划船社",
  "campus.has_m_cc_ski_club": "男子cc滑雪社",
  "campus.has_m_dh_ski_club": "男子dh滑雪社",
  "campus.has_m_soccer_club": "男子足球社",
  "campus.has_m_softball_club": "男子壘球社",
  "campus.has_m_squash_club": "男子壁球社",
  "campus.has_m_swim_club": "男子游泳社",
  "campus.has_m_tennis_club": "男子網球社",
  "campus.has_m_track_club": "男子田徑社",
  "campus.has_m_vball_club": "男子排球社",
  "campus.has_m_wpolo_club": "男子水球社",
  "campus.has_m_wrestling_club": "男子摔角社",
  "campus.im_sports": "校內體育競賽",
  "campus.clubs": "社團",
  "campus.sororities": "姐妹會",
  "campus.frats": "兄弟會",
  "campus.rotc": "ROTC",
  "campus.created_at": "campus 資料庫新增日期",
  "campus.updated_at": "campus 資料庫修改日期",
  "moneys.id": "moneys 資料庫 ID",
  "moneys.school_id": "學校 ID",
  "moneys.year": "年份",
  "moneys.total_tuition": "就讀費用",
  "moneys.tuition_fee": "學費",
  "moneys.room_fee": "宿舍及伙食費",
  "moneys.books_fee": "書籍及材料費",
  "moneys.other_fee": "其他開銷",
  "moneys.payment_plans": "付款方案",
  "moneys.finaid_email": "獎學金email",
  "moneys.finaid_website": "申請獎學金網站",
  "moneys.finaid_net_price_calc_website": "費用計算工具",
  "moneys.finaid_app_priority_deadline": "申請優先截止日期",
  "moneys.finaid_app_final_deadline": "申請截止日期",
  "moneys.finaid_notification_date": "通知日期",
  "moneys.finaid_methodology": "獎助學金申請方式",
  "moneys.finaid_fafsa_code": "FAFSA 編號",
  "moneys.finaid_fafsa_cost": "FAFSA 費用",
  "moneys.finaid_css_cost": "CSS 費用",
  "moneys.finaid_business_farm_supplement": "商業及農業附件 (Business farm supplement)",
  "moneys.finaid_noncustodial_statement": "非監護人文件 (Noncustodial statement)",
  "moneys.finaid_freshmen_applicant_count": "大一新生助學金申請人數",
  "moneys.finaid_freshmen_needed_count": "大一新生有助學金需求人數",
  "moneys.finaid_freshmen_received_count": "大一新生獲得助學金人數",
  "moneys.finaid_freshmen_fully_met_count": "大一新生獲得全額助學金人數 (Need Fully Met)",
  "moneys.finaid_freshmen_avg_pct_met": "大一新生满足助学金需求比率 (Average Percent of Need Met)",
  "moneys.finaid_freshmen_applicant_pct": "大一新生助學金申請比率",
  "moneys.finaid_freshmen_needed_pct": "大一新生有助學金需求比率",
  "moneys.finaid_freshmen_received_pct": "大一新生獲得助學金比率",
  "moneys.finaid_freshmen_fully_met_pct": "大一新生獲得全額助學金比率 (Need Fully Met)",
  "moneys.finaid_freshmen_avg_award": "大一新生平均助學金",
  "moneys.finaid_freshman_merit_received_count": "大一生獲得獎學金數",
  "moneys.finaid_freshman_merit_received_pct": "大一生獲得獎學金百分比",
  "moneys.finaid_freshman_merit_received_avg": "大一生獲得平均獎學金",
  "moneys.finaid_undergrad_applicant_count": "大學（本科）生助學金申請人數",
  "moneys.finaid_undergrad_needed_count": "大學（本科）生有助學金需求人數",
  "moneys.finaid_undergrad_received_count": "大學（本科）生獲得助學金人數",
  "moneys.finaid_undergrad_fully_met_count": "大學（本科）生獲得全額助學金人數 (Need Fully Met)",
  "moneys.finaid_undergrad_applicant_pct": "大學（本科）生助學金申請比率",
  "moneys.finaid_undergrad_needed_pct": "大學（本科）生有助學金需求比率",
  "moneys.finaid_undergrad_received_pct": "大學（本科）生獲得助學金比率",
  "moneys.finaid_undergrad_fully_met_pct": "大學（本科）生獲得全額助學金比率 (Need Fully Met)",
  "moneys.finaid_undergrad_avg_pct_met": "大學（本科）生满足助獎學金需求比率 (Average Percent of Need Met)",
  "moneys.finaid_undergrad_avg_award": "大學（本科）生平均助學金",
  "moneys.need_based_scholarship_programs": "Need-based 獎學金方案",
  "moneys.finaid_undergrad_merit_received_count": "Merit 學業獎學金人數",
  "moneys.finaid_undergrad_merit_received_pct": "Merit 學業獎金百分比",
  "moneys.finaid_undergrad_merit_received_avg": "Merit 學業平均獎學金",
  "moneys.student_loans_pct": "有貸款的畢業生 (2018)",
  "moneys.student_indebtedness": "畢業生平均學貸 (2018)",
  "moneys.federal_loan_programs": "政府貸款",
  "moneys.state_loan_is_available": "可否使用州貸款",
  "moneys.other_loan_programs": "其他貸款",
  "moneys.need_based_programs": "給需補助的學生",
  "moneys.nonneed_based_programs": "給不需補助的學生",
  "moneys.workstudy_programs": "工讀計畫",
  "moneys.workstudy_avg_earning": "平均校園工作收入",
  "moneys.created_at": "moneys 資料新增日期",
  "moneys.updated_at": "moneys 資料修改日期",
  "students.id": "students 資料庫 ID",
  "students.school_id": "學校 ID",
  "students.coed": "男女合校",
  "students.undergrad_count": "大學 (本科) 學生人數",
  "students.undergrad_w_count": "大學 (本科) 女性學生人數",
  "students.undergrad_w_pct": "大學 (本科) 女性學生比率",
  "students.undergrad_m_count": "大學 (本科) 男性學生人數",
  "students.undergrad_m_pct": "大學 (本科) 男性學生比率",
  "students.fulltime_undergrad_count": "全職大學 (本科) 生人數",
  "students.indian_pct": "印度裔百分比",
  "students.asian_pct": "亞裔百分比",
  "students.black_pct": "黑裔百分比",
  "students.hispanic_pct": "中南美洲裔百分比",
  "students.mixed_pct": "混血裔百分比",
  "students.pi_pct": "太平洋島裔百分比",
  "students.white_pct": "白人百分比",
  "students.unknown_pct": "未知百分比",
  "students.intl_pct": "國際生百分比",
  "students.intl_countries_count": "學生國家數",
  "students.avg_age": "平均年齡",
  "students.grad_count": "研究所",
  "students.return_rate": "大一生就學穩定率",
  "students.graduate_4_yrs": "畢業率 (4年內)",
  "students.graduate_5_yrs": "畢業率 (5年內)",
  "students.graduate_6_yrs": "畢業率 (6年內)",
  "students.employment_pct": "畢業就業率 (畢業後6個月內)",
  "students.pursue_grad_studies_pct": "畢業後直接就讀研究所",
  "students.grad_disciplines_pursued": "職業領域",
  "students.created_at": "students 資料新增日期",
  "students.updated_at": "students 資料修改日期"
};

export const trueOrFalseColumns = [
  "campus.has_w_baseball",
  "campus.has_w_basketball",
  "campus.has_w_crew",
  "campus.has_w_cc",
  "campus.has_w_fencing",
  "campus.has_w_hockey",
  "campus.has_w_football",
  "campus.has_w_golf",
  "campus.has_w_icehockey",
  "campus.has_w_lax",
  "campus.has_w_rugby",
  "campus.has_w_sailing",
  "campus.has_w_cc_ski",
  "campus.has_w_baseball_scho",
  "campus.has_w_basketball_scho",
  "campus.has_w_crew_scho",
  "campus.has_w_cc_scho",
  "campus.has_w_fencing_scho",
  "campus.has_w_hockey_scho",
  "campus.has_w_football_scho",
  "campus.has_w_golf_scho",
  "campus.has_w_icehockey_scho",
  "campus.has_w_lax_scho",
  "campus.has_w_rugby_scho",
  "campus.has_w_sailing_scho",
  "campus.has_w_cc_ski_scho",
  "campus.has_m_baseball",
  "campus.has_m_basketball",
  "campus.has_m_crew",
  "campus.has_m_cc",
  "campus.has_m_fencing",
  "campus.has_m_hockey",
  "campus.has_m_football",
  "campus.has_m_golf",
  "campus.has_m_icehockey",
  "campus.has_m_lax",
  "campus.has_m_rugby",
  "campus.has_m_sailing",
  "campus.has_m_cc_ski",
  "campus.has_m_baseball_scho",
  "campus.has_m_basketball_scho",
  "campus.has_m_crew_scho",
  "campus.has_m_cc_scho",
  "campus.has_m_fencing_scho",
  "campus.has_m_hockey_scho",
  "campus.has_m_football_scho",
  "campus.has_m_golf_scho",
  "campus.has_m_icehockey_scho",
  "campus.has_m_lax_scho",
  "campus.has_m_rugby_scho",
  "campus.has_m_sailing_scho",
  "campus.has_m_cc_ski_scho",
  "campus.has_w_dh_ski",
  "campus.has_w_soccer",
  "campus.has_w_softball",
  "campus.has_w_squash",
  "campus.has_w_swim",
  "campus.has_w_tennis",
  "campus.has_w_track",
  "campus.has_w_vball",
  "campus.has_w_wpolo",
  "campus.has_w_wrestling",
  "campus.has_w_dh_ski_scho",
  "campus.has_w_soccer_scho",
  "campus.has_w_softball_scho",
  "campus.has_w_squash_scho",
  "campus.has_w_swim_scho",
  "campus.has_w_tennis_scho",
  "campus.has_w_track_scho",
  "campus.has_w_vball_scho",
  "campus.has_w_wpolo_scho",
  "campus.has_w_wrestling_scho",
  "campus.has_m_dh_ski",
  "campus.has_m_soccer",
  "campus.has_m_softball",
  "campus.has_m_squash",
  "campus.has_m_swim",
  "campus.has_m_tennis",
  "campus.has_m_track",
  "campus.has_m_vball",
  "campus.has_m_wpolo",
  "campus.has_m_wrestling",
  "campus.has_m_dh_ski_scho",
  "campus.has_m_soccer_scho",
  "campus.has_m_softball_scho",
  "campus.has_m_squash_scho",
  "campus.has_m_swim_scho",
  "campus.has_m_tennis_scho",
  "campus.has_m_track_scho",
  "campus.has_m_vball_scho",
  "campus.has_m_wpolo_scho",
  "campus.has_m_wrestling_scho",
  "campus.has_w_baseball_club",
  "campus.has_w_basketball_club",
  "campus.has_w_crew_club",
  "campus.has_w_cc_club",
  "campus.has_w_fencing_club",
  "campus.has_w_hockey_club",
  "campus.has_w_football_club",
  "campus.has_w_golf_club",
  "campus.has_w_icehockey_club",
  "campus.has_w_lax_club",
  "campus.has_w_rugby_club",
  "campus.has_w_sailing_club",
  "campus.has_w_cc_ski_club",
  "campus.has_w_dh_ski_club",
  "campus.has_w_soccer_club",
  "campus.has_w_softball_club",
  "campus.has_w_squash_club",
  "campus.has_w_swim_club",
  "campus.has_w_tennis_club",
  "campus.has_w_track_club",
  "campus.has_w_vball_club",
  "campus.has_w_wpolo_club",
  "campus.has_w_wrestling_club",
  "campus.has_m_baseball_club",
  "campus.has_m_basketball_club",
  "campus.has_m_crew_club",
  "campus.has_m_cc_club",
  "campus.has_m_fencing_club",
  "campus.has_m_hockey_club",
  "campus.has_m_football_club",
  "campus.has_m_golf_club",
  "campus.has_m_icehockey_club",
  "campus.has_m_lax_club",
  "campus.has_m_rugby_club",
  "campus.has_m_sailing_club",
  "campus.has_m_cc_ski_club",
  "campus.has_m_dh_ski_club",
  "campus.has_m_soccer_club",
  "campus.has_m_softball_club",
  "campus.has_m_squash_club",
  "campus.has_m_swim_club",
  "campus.has_m_tennis_club",
  "campus.has_m_track_club",
  "campus.has_m_vball_club",
  "campus.has_m_wpolo_club",
  "campus.has_m_wrestling_club",
  "admissions.rd_deadline_is_rolling",
  "admissions.has_application_fee_waiver",
  "admissions.has_waitlist",
  "admissions.can_defer",
  "admissions.has_ed1",
  "admissions.has_ed2",
  "admissions.ed1_deadline_is_rolling",
  "admissions.ed2_deadline_is_rolling",
  "admissions.ed1_notification_date_is_rolling",
  "admissions.ed2_notification_date_is_rolling",
  "admissions.has_ea1",
  "admissions.has_ea2",
  "admissions.ea1_deadline_is_rolling",
  "admissions.ea2_deadline_is_rolling",
  "admissions.ea1_notification_date_is_rolling",
  "admissions.ea2_notification_date_is_rolling",
  "admissions.has_tr",
  "admissions.tr_deadline_is_rolling",
  "admissions.tr_notification_date_is_rolling",
  "admissions.has_rea",
  "admissions.has_commonapp_supplement",
  "admissions.has_universalapp_supplement",
  "admissions.has_electronic_supplement",
  "admissions.impt_first_gen",
  "admissions.impt_state_residency",
  "academics.has_summer_session",
  "academics.has_study_abroad",
  "academics.has_online_degree",
  "academics.has_gened",
  "academics.has_comp_req",
  "academics.has_lang_req",
  "academics.has_stem_req",
  "academics.accepts_ib",
  "academics.accepts_ap",
  "academics.has_sophomore_standing",
  "academics.has_campus_library",
  "academics.has_internet_access",
  "academics.has_remedial_instruction",
  "academics.has_tutoring",
  "academics.has_disability_service",
  "campus.has_alarm_devices",
  "campus.has_security",
  "campus.has_escort",
  "campus.has_e_entrance",
  "campus.has_health_serv",
  "campus.has_counseling",
  "campus.has_childcare",
  "admissions.can_transfer"
];

export const moneyColumns = [
  "moneys.workstudy_avg_earning",
  "moneys.student_indebtedness",
  "moneys.finaid_freshmen_avg_award",
  "moneys.total_tuition",
  "moneys.tuition_fee",
  "moneys.room_fee",
  "moneys.books_fee",
  "moneys.other_fee"
];

export const percentColumns = [
  "students.indian_pct",
  "students.asian_pct",
  "students.black_pct",
  "students.hispanic_pct",
  "students.mixed_pct",
  "students.pi_pct",
  "students.white_pct",
  "students.unknown_pct",
  "students.intl_pct",
  "students.undergrad_w_pct",
  "students.undergrad_m_pct",
  "moneys.student_loans_pct",
  "moneys.finaid_undergrad_applicant_pct",
  "moneys.finaid_undergrad_needed_pct",
  "moneys.finaid_undergrad_received_pct",
  "moneys.finaid_undergrad_fully_met_pct",
  "moneys.finaid_undergrad_avg_pct_met",
  "moneys.finaid_freshmen_applicant_pct",
  "moneys.finaid_freshmen_needed_pct",
  "moneys.finaid_freshmen_received_pct",
  "moneys.finaid_freshmen_fully_met_pct",
  "moneys.finaid_freshmen_avg_pct_met",
  "campus.sororities",
  "campus.frats",
  "campus.housing_pct",
  "campus.offcampus_pct",
  "academics.phd_faculty_pct",
  "academics.class_size_9_pct",
  "academics.class_size_19_pct",
  "academics.class_size_29_pct",
  "academics.class_size_39_pct",
  "academics.class_size_49_pct",
  "academics.class_size_99_pct",
  "academics.class_size_100_pct",
  "academics.section_size_9_pct",
  "academics.section_size_19_pct",
  "academics.section_size_29_pct",
  "academics.section_size_39_pct",
  "academics.section_size_49_pct",
  "academics.section_size_99_pct",
  "academics.section_size_100_pct",
  "admissions.hs_rank_10_pct",
  "admissions.hs_rank_25_pct",
  "admissions.hs_rank_50_pct",
  "admissions.national_merit_pct",
  "admissions.valedictorian_pct",
  "admissions.class_president_pct",
  "admissions.student_gov_pct",
  "admissions.hs_rank_10_pct",
  "admissions.hs_rank_25_pct",
  "admissions.hs_rank_50_pct",
  "admissions.avg_act30_pct",
  "admissions.avg_act24_pct",
  "admissions.avg_act18_pct",
  "admissions.avg_act12_pct",
  "admissions.avg_act6_pct",
  "admissions.avg_act0_pct",
  "admissions.sat_math700_pct",
  "admissions.sat_math600_pct",
  "admissions.sat_math500_pct",
  "admissions.sat_math400_pct",
  "admissions.sat_math300_pct",
  "admissions.sat_math200_pct",
  "admissions.sat_english700_pct",
  "admissions.sat_english600_pct",
  "admissions.sat_english500_pct",
  "admissions.sat_english400_pct",
  "admissions.sat_english300_pct",
  "admissions.sat_english200_pct",
  "admissions.gpa375_pct",
  "admissions.gpa350_pct",
  "admissions.gpa325_pct",
  "admissions.gpa300_pct",
  "admissions.gpa250_pct",
  "admissions.gpa200_pct",
  "admissions.acceptance_pct_overall",
  "admissions.acceptance_pct_w",
  "admissions.acceptance_pct_m",
  "admissions.yield_pct_overall",
  "admissions.yield_pct_w",
  "admissions.yield_pct_m",
  "students.pursue_grad_studies_pct",
  "students.employment_pct",
  "students.graduate_6_yrs",
  "students.graduate_5_yrs",
  "students.graduate_4_yrs",
  "students.return_rate",
  "students.avg_age"
];

export const importantLevelColumns = [
  "admissions.impt_gpa",
  "admissions.impt_tests",
  "admissions.impt_rank",
  "admissions.impt_recommendations",
  "admissions.impt_essay",
  "admissions.impt_interview",
  "admissions.impt_interest"
];

export const numberColumns = [
  "admissions.applicant_overall_count",
  "admissions.applicant_w_count",
  "admissions.applicant_m_count",
  "admissions.admitted_overall_count",
  "admissions.admitted_w_count",
  "admissions.admitted_m_count",
  "admissions.enrolled_overall_count",
  "admissions.enrolled_w_count",
  "admissions.enrolled_m_count",
  "admissions.ed_applicant_overall_count",
  "admissions.ea_applicant_overall_count",
  "admissions.ed_admitted_overall_count",
  "admissions.ea_admitted_overall_count",
  "admissions.waitlist_offered_count",
  "admissions.waitlist_accepted_count",
  "admissions.waitlist_admitted_count",
  "academics.fulltime_faculty_count",
  "academics.parttime_faculty_count",
  "academics.library_holdings_count",
  "academics.campus_computers_count",
  "campus.city_population",
  "moneys.total_tuition",
  "moneys.tuition_fee",
  "moneys.room_fee",
  "moneys.books_fee",
  "moneys.other_fee",
  "moneys.finaid_freshmen_applicant_count",
  "moneys.finaid_freshmen_needed_count",
  "moneys.finaid_freshmen_received_count",
  "moneys.finaid_freshmen_fully_met_count",
  "moneys.finaid_freshman_merit_received_count",
  "moneys.finaid_undergrad_applicant_count",
  "moneys.finaid_undergrad_needed_count",
  "moneys.finaid_undergrad_received_count",
  "moneys.finaid_undergrad_fully_met_count",
  "moneys.finaid_undergrad_merit_received_count",
  "students.undergrad_count",
  "students.undergrad_w_count",
  "students.undergrad_m_count",
  "students.fulltime_undergrad_count",
  "students.intl_countries_count",
  "students.grad_count"
];
