<template>
  <div class="container-fluid container">
    <el-dialog
      title="這些名稱包含兩所學校，請選擇指定學校："
      :visible.sync="choiceCollegeAttachLinkDialog"
      width="600px"
    >
      <div
        class="collegeName"
        :key="collegeName"
        v-for="collegeName in collegeNames"
      >
        <div v-if="colleges[collegeName].length > 1">
          <b>{{ collegeName }}</b>
          <div class="matchColleges">
            <div :key="college.id" v-for="college in colleges[collegeName]">
              <el-checkbox
                :value="college.selected"
                @input="() => selectedCollege(collegeName, college.id)"
              />
              <span v-if="lang === 'EN-US'">{{ college.name[langList[lang]] }}</span>
              <span v-else>
                {{ college.name[langList[lang]] }}&nbsp;({{ college.name["en"] }})
              </span>
              <span>{{ `${college.city}, ${college.state}.` }}</span>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="() => (choiceCollegeAttachLinkDialog = false)"
        >
          {{ $t("button.confirm") }}
        </el-button>
      </span>
    </el-dialog>
    <Breadcrumb />
    <h1>{{ pageTitle }}</h1>
    <hr class="separate-line" />
    <template v-if="currentStep === 1">
      <el-form @submit.prevent.native="fetchSchools">
        <el-form-item>
          <template slot="label">
            1. 輸入大學名字（一個為一行）
          </template>
          <el-input type="textarea" :rows="6" v-model="collegesString" />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" native-type="submit">
            Next
          </el-button>
        </el-form-item>
      </el-form>
    </template>
    <template v-if="currentStep === 2">
      <div v-if="collegeNames.length !== 0">
        <el-alert
          v-if="someSchoolHaveNoAnyAlias"
          class="haveNoAnyAlias"
          type="warning"
          show-icon
        >
          <template slot="title">
            注意！有學校沒連結，建議先幫這些學校
            <router-link :to="{ name: 'collegeAliases' }"
              >添加大學別名</router-link
            >
            再繼續。
          </template>
        </el-alert>
        <table>
          <tr :key="collegeName" v-for="collegeName in collegeNames">
            <td>
              <span v-if="colleges[collegeName].length === 0">
                {{ collegeName }}
              </span>
              <template v-else>
                <a
                  :href="`https://ivy-way.com/schools/${getCollegeSlug(collegeName)}`"
                  >{{ collegeName }}</a
                >
              </template>
            </td>
          </tr>
        </table>
        <hr class="separate-line" />
        <div class="generateType">
          <div>
            2. 要自己選擇顯示欄位還是用
            <a
              href="https://blog.ivy-way.com/best-10-colleges-in-alabama/?preview_id=44956&preview_nonce=ef0af4df4f&preview=true&_thumbnail_id=50038"
            >
              現有文章模板
            </a>
            產生學校內容？
          </div>
          <el-radio-group v-model="generateType" size="mini">
            <el-radio :label="generateTypeEnum.STATIC" border>
              用現有文章模板產生
            </el-radio>
            <el-radio :label="generateTypeEnum.CUSTOM" border>
              自己選擇顯示的欄位
            </el-radio>
          </el-radio-group>
        </div>
        <div v-if="generateType === generateTypeEnum.STATIC">
          <el-row>
            <el-col>
              <el-button type="primary" @click="prevStep">
                Previous
              </el-button>
              <el-button type="primary" @click="generateStaticHtml">
                Submit
              </el-button>
            </el-col>
          </el-row>
        </div>
        <el-form
          v-if="generateType === generateTypeEnum.CUSTOM"
          @submit.prevent.native="
            () => {
              nextStep();
              setSortToSchoolColumns();
              clearSchoolsInfo();
            }
          "
        >
          <el-form-item>
            <template slot="label">
              請選擇需要顯示的欄位
            </template>
          </el-form-item>
          <el-tabs class="withoutFromItem" v-model="activeTabName">
            <el-tab-pane
              :key="tableName"
              v-for="tableName in Object.keys(tables)"
              :label="tableName"
              :name="tableName"
            >
              <el-checkbox-group :value="selectedColumns">
                <div class="columns">
                  <el-checkbox
                    :key="column"
                    v-for="column in tables[tableName]"
                    :label="`${tableName}.${column}`"
                    @change="isSelect => selectedColumn(`${tableName}.${column}`, isSelect)"
                  >
                    {{ column }}
                  </el-checkbox>
                </div>
              </el-checkbox-group>
            </el-tab-pane>
          </el-tabs>
          <el-form-item>
            <el-button type="primary" @click="prevStep">
              Previous
            </el-button>
            <el-button type="primary" native-type="submit">
              Next
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </template>
    <template v-if="currentStep === 3">
      <el-form
        v-if="generateType === generateTypeEnum.CUSTOM"
        @submit.prevent.native="() => {
          getSchoolInformation();
          clearSchoolsInfo();
        }"
      >
        <el-form-item>
          <template slot="label">
            3. 輸入欄位的順序
          </template>
        </el-form-item>
        <div class="withoutFromItem">
          <div
            class="columnSort"
            :key="selectedColumn.name"
            v-for="selectedColumn in selectedColumnsAndOrder"
          >
            <el-input-number
              class="columnSortInput"
              controls-position="right"
              :value="selectedColumn.sort"
              @change="sort => changeColumnSort(selectedColumn.name, sort)"
            />
            {{ selectedColumn.name }}
          </div>
        </div>
        <el-form-item>
          <el-button type="primary" @click="prevStep">
            Previous
          </el-button>
          <el-button type="primary" native-type="submit">
            Submit
          </el-button>
        </el-form-item>
      </el-form>
      <div v-if="generateType === generateTypeEnum.STATIC">
        <el-row>
          <el-col>
            <el-button type="primary" @click="prevStep">
              Previous
            </el-button>
          </el-col>
        </el-row>
      </div>
      <template v-if="schoolsInfo.length !== 0">
        <hr class="separate-line" />
        <el-button size="mini" type="primary" @click="copySchoolsInfo">
          {{ schoolsInfoCopied ? 'Copied!' : 'Copy Result' }}
        </el-button>
        <div id="schoolsInfo">
          <div
            class="schoolInfo"
            :key="collegeId"
            v-for="(collegeId, index) in collegeIds"
          >
            {{ `${generateSchoolInfoFormatString(index + 1, collegeId)}` }}
          </div>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import schoolApi from "@/apis/school.js";
import Breadcrumb from "@/components/Breadcrumb";
import schoolDict, {
  percentColumns,
  moneyColumns,
  numberColumns,
  importantLevelColumns,
  trueOrFalseColumns
} from "@/views/postCreator/schoolDict.js";
import moneyMixin from "@/mixins/money";

export default {
  metaInfo() {
    return {
      title: `${this.pageTitle} - Ivy-Way Academy`
    };
  },
  components: {
    Breadcrumb
  },
  mixins: [moneyMixin],
  computed: {
    pageTitle() {
      return this.$t("pageTitle.postCreator");
    },
    lang() {
      return this.$store.getters["user/langValue"];
    },
    langList() {
      return {
        "ZH-TW": "tw",
        "EN-US": "en",
        "ZH-CN": "cn"
      };
    },
    collegeNames() {
      return Object.keys(this.colleges);
    },
    collegeIds() {
      return Object.keys(this.colleges).map(collegeName => (
        this.colleges[collegeName].find(({ selected }) => selected).id
      ));
    },
    selectedColumns() {
      return this.selectedColumnsAndOrder.map(column => column.name);
    },
    someSchoolHaveNoAnyAlias() {
      return Object.keys(this.colleges).some(
        college => this.colleges[college].length === 0
      );
    },
    headerStyle() {
      return `
        style="
          font-size: clamp(27px, 4vw, 32px);
          margin: 20px 0px;
          font-family: -apple-system, BlinkMacSystemFont , Roboto, Oxygen-Sans, Ubuntu, Cantarell, sans-serif;
        "
      `;
    },
    generateTypeEnum() {
      return {
        CUSTOM: "custom",
        STATIC: "static"
      };
    }
  },
  data() {
    return {
      currentStep: 1,
      collegesString: "",
      choiceCollegeAttachLinkDialog: false,
      colleges: {},
      tables: {},
      activeTabName: "",
      selectedColumnsAndOrder: [],
      schoolsInfo: [],
      generateType: null,
      schoolsInfoCopied: false
    };
  },
  async created() {
    const { tables } = await schoolApi.getSchoolColumn();
    this.activeTabName = Object.keys(tables)[0];
    this.tables = tables;
    this.generateType = this.generateTypeEnum.STATIC;
  },
  methods: {
    prevStep() {
      this.currentStep = this.currentStep - 1;
    },
    nextStep() {
      this.currentStep = this.currentStep + 1;
    },
    clearSchoolsInfo() {
      this.schoolsInfo = [];
      this.schoolsInfoCopied = false;
    },
    async fetchSchools() {
      const { schools: colleges } = await schoolApi.getSchoolByNameOrAlias(this.collegesString);
      this.colleges = colleges;
      this.collegeNames.forEach(collegeName => {
        if (colleges[collegeName].length > 0) {
          colleges[collegeName][0].selected = true;
        }
      });
      if (this.hasNameMatchMultipleCollege()) {
        this.choiceCollegeAttachLinkDialog = true;
      }
      this.nextStep();
    },
    hasNameMatchMultipleCollege() {
      return this.collegeNames.some(
        collegeName => this.colleges[collegeName].length >= 2
      );
    },
    async getSchoolInformation() {
      let postColumns = {};
      this.selectedColumnsAndOrder.forEach(({ name }) => {
        const [table, column] = name.split(".");
        postColumns = {
          ...postColumns,
          [table]: [...(postColumns[table] || []), column]
        };
      });
      const { schools } = await schoolApi.getSchoolInfo({
        school_ids: this.collegeIds,
        ...postColumns
      });
      this.schoolsInfo = schools;
    },
    selectedCollege(collegeName, collegeId) {
      this.colleges = {
        ...this.colleges,
        [collegeName]: this.colleges[collegeName].map(college => ({
          ...college,
          selected: college.id === collegeId
        }))
      };
    },
    selectedColumn(column, isSelect) {
      if (isSelect) {
        this.selectedColumnsAndOrder = [
          ...this.selectedColumnsAndOrder,
          { name: column, sort: "" }
        ];
      } else {
        this.selectedColumnsAndOrder = this.selectedColumnsAndOrder
          .filter((selectedColumn) => (
            selectedColumn.name !== column
          ));
      }
    },
    setSortToSchoolColumns() {
      this.selectedColumnsAndOrder = this.selectedColumnsAndOrder
        .map((selectedColumn, index) => ({
          ...selectedColumn,
          sort: index + 1
        }));
    },
    changeColumnSort(column, sort) {
      this.selectedColumnsAndOrder = this.selectedColumnsAndOrder
        .map((selectedColumn) => {
          if (selectedColumn.name === column) {
            return { ...selectedColumn, sort };
          }

          return selectedColumn;
        });
    },
    getCollegeSlug(collegeName) {
      const college = this.colleges[collegeName].find(
        college => college.selected
      );
      return college.slug;
    },
    generateStaticHtml() {
      const staticColumns = [
        "admissions.ao_city",
        "admissions.ao_state",
        "admissions.acceptance_pct_overall",
        "moneys.tuition_fee",
        "academics.popular_majors",
        "admissions.avg_sat_math_min",
        "admissions.avg_sat_math_max",
        "admissions.avg_sat_english_min",
        "admissions.avg_sat_english_max",
        "admissions.avg_act_min",
        "admissions.avg_act_max"
      ];
      staticColumns.forEach(staticColumn => {
        this.selectedColumn(staticColumn, true);
      });
      this.nextStep();
      this.getSchoolInformation();
    },
    generateSchoolInfoFormatString(order, schoolId) {
      const schoolInfo = this.schoolsInfo.find(school => school.id === schoolId);
      switch (this.generateType) {
        case this.generateTypeEnum.CUSTOM:
          return this.getSchoolInfoFormatString(order, schoolInfo);
        case this.generateTypeEnum.STATIC:
          return this.getStaticSchoolInfoFormatString(order, schoolInfo);
        default:
      }
    },
    copySchoolsInfo() {
      const text = document.getElementById("schoolsInfo").innerText;
      const copyToClipboard = str => {
        const el = document.createElement("textarea");
        el.value = str;
        document.body.appendChild(el);
        el.select();
        document.execCommand("copy");
        document.body.removeChild(el);
      };
      copyToClipboard(text);
      this.schoolsInfoCopied = true;
    },
    getStaticSchoolInfoFormatString(order, schoolInfo) {
      const satMinScore = (schoolInfo.admissions_avg_sat_math_min || 0) + (schoolInfo.admissions_avg_sat_english_min || 0);
      const satMaxScore = (schoolInfo.admissions_avg_sat_math_max || 0) + (schoolInfo.admissions_avg_sat_english_max || 0);
      const satRange = `<li><b>SAT：</b>${satMinScore}-${satMaxScore}</li>`;
      const actMinScore = schoolInfo.admissions_avg_act_min || 0;
      const actMaxScore = schoolInfo.admissions_avg_act_max || 0;
      const actRange = `<li><b>ACT：</b>${schoolInfo.admissions_avg_act_min || 0}-${schoolInfo.admissions_avg_act_max || 0}</li>`;
      return `
        <!-- wp:heading {"level":2} -->
        <h2 ${this.headerStyle}>
          ${order}. <a style="color: #43a06b;" href="https://ivy-way.com/schools/${schoolInfo.slug}/overview" target="_blank" rel="noreferrer noopener">${schoolInfo.name.tw} (${schoolInfo.name.en})</a>
        </h2>
        <!-- /wp:heading -->
        <!-- wp:image {"sizeSlug":"large","linkDestination":"none"} -->
        <figure class="wp-block-image size-large">
          <img src="${schoolInfo.website_image}" alt="" class="wp-image"/>
        </figure>
        <!-- /wp:image -->
        <!-- wp:list -->
        <ul>
          <li>城市/州：${schoolInfo.admissions_ao_city}, ${schoolInfo.admissions_ao_state}</li>
          <li>錄取率: ${schoolInfo.admissions_acceptance_pct_overall}%</li>
          <li>學費：$${this.addThousandsComma(schoolInfo.moneys_tuition_fee)}/年</li>
          <li>
            入學考試平均分數：
            <ul>
              ${satMinScore + satMinScore === 0 ? "" : satRange}
              ${actMinScore + actMaxScore === 0 ? "" : actRange}
            </ul>
          </li>
          <li>最受歡迎的科系：${schoolInfo.academics_popular_majors}</li>
        </ul>
        <!-- /wp:list -->
        <!-- wp:paragraph -->
        <p>${schoolInfo.overview.tw}</p>
        <!-- /wp:paragraph -->
        <!-- wp:separator -->
        <hr class="wp-block-separator"/>
        <!-- /wp:separator -->
      `;
    },
    getSchoolInfoFormatString(order, schoolInfo) {
      const getColumnsInfo = () => {
        const sortedColumns = [...this.selectedColumnsAndOrder].sort(
          (p, n) => p.sort - n.sort
        );
        let columnsInfo = "";
        const importantLevelDict = {
          1: "非常重要",	2: "重要",	3: "會納入考量",	4: "不納入考量"
        };
        sortedColumns.forEach(column => {
          let [tableName, columnName] = column.name.split(".");
          const columnLabel = schoolDict[column.name];
          let columnValue = schoolInfo[`${tableName}_${columnName}`] || "-";

          if (numberColumns.includes(column.name)) {
            columnValue = this.addThousandsComma(columnValue);
          }

          if (columnValue === "-") {
            columnsInfo += `
              <li>${columnLabel}：${columnValue}</li>`;
          } else {
            if (percentColumns.includes(column.name)) {
              columnsInfo += `
                <li>${columnLabel}：${columnValue}%</li>`;
            } else if (importantLevelColumns.includes(column.name)) {
              columnsInfo += `
                <li>${columnLabel}：${importantLevelDict[columnValue]}</li>`;
            } else if (moneyColumns.includes(column.name)) {
              columnsInfo += `
                <li>${columnLabel}：$${columnValue}</li>`;
            } else if (trueOrFalseColumns.includes(column.name)) {
              columnsInfo += `
                <li>${columnLabel}：${columnValue ? "是" : "否"}</li>`;
            } else {
              columnsInfo += `
                <li>${columnLabel}：${columnValue}</li>`;
            }
          }
        });
        return columnsInfo;
      };

      return `
        <!-- wp:heading {"level":2} -->
        <h2 ${this.headerStyle}>
          ${order}. <a style="color: #2271b1;" href="https://ivy-way.com/schools/${schoolInfo.slug}/overview" target="_blank" rel="noreferrer noopener">${schoolInfo.name.en}</a>
        </h2>
        <!-- /wp:heading -->
        <!-- wp:image {"sizeSlug":"large","linkDestination":"none"} -->
        <figure class="wp-block-image size-large">
          <img src="${schoolInfo.website_image}" alt="" class="wp-image"/>
        </figure>
        <!-- /wp:image -->
        <!-- wp:list -->
        <ul id="">${getColumnsInfo()}
        </ul>
        <!-- /wp:list -->
        <!-- wp:paragraph -->
        <p>${schoolInfo.overview.tw}</p>
        <!-- /wp:paragraph -->
        <!-- wp:separator -->
        <hr class="wp-block-separator"/>
        <!-- /wp:separator -->
      `;
    }
  }
};
</script>

<style scoped>
.container {
  margin-bottom: 40px;
}

.collegeName {
  margin: 8px 0px;
}

.matchColleges {
  margin-left: 20px;
}

.matchColleges > .title {
  color: #696969;
  font-size: 14px;
}

.haveNoAnyAlias {
  margin-bottom: 24px;
}

.columns {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.columnSort {
  display: grid;
  grid-template-columns: 100px 1fr;
  gap: 12px;
  align-items: center;
  margin: 8px 0px;
}

.columnSortInput {
  width: 100px;
}

.schoolInfo {
  white-space: pre-line;
}

.withoutFromItem {
  margin-top: -20px;
  margin-bottom: 20px;
}

.generateType {
  display: flex;
}

::v-deep .el-radio {
  margin-left: 8px;
  margin-right: 0px;
}
</style>
